<template>
  <div class="order flex container">
    <div v-if="productList" class="order__block order__main-block">
      <div class="order__head main-block__head">
        <h1 v-if="$route.params.type === 'active-order'" class="main-block__title titles pl-0">
          {{ productList.description ? productList.description : "" }}
        </h1>
        <h1 v-if="$route.params.type == 'order-points'" class="main-block__title titles pl-0">
          {{ productList.name ? productList.name : "" }}
        </h1>
      </div>
      <ul class="order-items">
        <li class="order-item flex">
          <p class="order-item__key">id заказа</p>
          <p>{{ productList.id ? productList.id : "" }}</p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Время создания</p>
          <p>
            {{ dateProduct(productList.date ? productList.date : productList.created_at) }}
          </p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Контерагент</p>
          <p>{{ productList.filial }}</p>
        </li>
        <!-- <li class="order-item flex">
          <p class="order-item__key">№ заказа</p>
          <p>{{ productList.order_id }}</p>
        </li> -->
        <li class="order-item flex">
          <p class="order-item__key">Филиал</p>
          <p>
            {{ productList.employee ? productList.employee.name : "Не выбран" }}
          </p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">№ реализации</p>
          <p>{{ productList.final_name }}</p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Количество контейнеров</p>
          <p v-if="$route.params.type == 'active-order'">
            {{ productList.count_points ? productList.count_points : 0 }}
          </p>
          <p v-if="$route.params.type == 'order-points'">
            <!-- {{
              productList.container_count
                ? productList.container_count.split(",").length
                : 0
            }} -->
            {{
              productList.containers && productList.containers.length > 0
                ? productList.containers.length
                : 0
            }}
          </p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Контейнеры</p>
          <div
            v-if="productList.containers && productList.containers.length > 0"
            class="order-item__containers"
          >
            <ul class="order-item__container-list">
              <li v-for="cont in productList.containers" :key="cont.id">
                {{ cont.code }}
              </li>
            </ul>
          </div>
          <p v-else>-</p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Объем</p>
          <p v-if="productList.containers.length">{{ (containerSize * productList.containers.length).toFixed(3) }} м <sup>3</sup></p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Вес товара</p>
          <p>{{ productList.weight ? productList.weight : 0 }} кг</p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Вес контейнеров</p>
          <p v-if="productList.containers.length">{{ (containerWeigth * productList.containers.length).toFixed(1) ?? 0 }} кг</p>
        </li>
        <h6 class="titles mt-4">Откуда</h6>
        <li class="order-item flex">
          <p class="order-item__key">Город выезда</p>
          <p>{{ productList.from || "" }}</p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Адрес выезда</p>
          <p>
            {{ productList.from }}
          </p>
        </li>
        <h6 class="titles mt-4">Куда</h6>
        <li class="order-item flex">
          <p class="order-item__key">Город доставки</p>
          <p>{{ productList.to || "" }}</p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Адрес доставки</p>
          <p>{{ productList.to || "" }}</p>
        </li>
        <h6 class="titles mt-4">Маршрут</h6>
        <li class="order-item flex">
          <p class="order-item__key">Водитель</p>
          <p>
            {{ productList.order_status ? productList.order_status.user.name : "Не выбран" }}
          </p>
        </li>
        <li class="order-item flex">
          <p class="order-item__key">Авто</p>
          <p>
            {{ productList.order_transport ? productList.order_transport.name : "Не выбран" }}
          </p>
        </li>
        <!--          <p>{{productList.transport ? productList.transport.transport.model.name : 'Не выбран'}}</p>-->

        <!--        <li class="order-item flex">-->
        <!--          <p class="order-item__key">Описание</p>-->
        <!--          <p>{{productList.description ? productList.description : 'Без описания'}}</p>-->
        <!--        </li>-->
        <!--        <li class="order-item flex">-->
        <!--          <p class="order-item__key">Причина</p>-->
        <!--          <p>{{productList.reason ? productList.reason : 'Без причины'}}</p>-->
        <!--        </li>-->
        <!--        <li class="order-item flex">-->
        <!--          <p>Контейнер</p>-->
        <!--          <p></p>-->
        <!--        </li>-->
      </ul>
    </div>
    <MapBlock class="order__map" :points="pointsList" />
  </div>
</template>

<script>
import { mapState } from "vuex";
// import axios from "axios";
export default {
  data() {
    return {
      productList: null,
      to: [],
      from: [],
      pointsList: [],
      lat: null,
      lng: null,
      containerSize: 0.0756,
      containerWeigth: 3.3
    };
  },
  created() {
    this.$store.dispatch("getOrdersActive");
    this.$store.dispatch("getMalfunctions");
    this.$store.dispatch("getNotifications");
    this.$store.dispatch("getOrderPoints");
  },
  methods: {
    dateProduct(value) {
      const date = value.split("T")[0].split("-").reverse();
      const arrDate = [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек",
      ];
      for (let i = 1; i <= 12; i++) {
        if ((date[1] * 10) / 10 === i) {
          date[1] = arrDate[i - 1];
        }
      }
      return date[1] + " " + date[0];
    },
  },
  computed: {
    ...mapState(["ordersActive", "malfunctions", "notifications", "orderPoints"]),
  },
  async mounted() {
    switch (this.$route.params.type) {
      case "active-order":
        this.productList = this.ordersActive.find((e) => e.id === +this.$route.query.id);
        break;
      case "faults":
        this.productList = this.malfunctions.find((e) => e.id === +this.$route.query.id);
        break;
      case "notifications":
        this.productList = this.notifications.find((e) => e.id === +this.$route.query.id);
        break;
      default:
        this.productList = this.orderPoints.data.find((e) => e.id === +this.$route.query.id);
        break;
    }
    this.pointsList = [
      [
        this.productList.lat ? this.productList.lat : this.productList.from_lat,
        this.productList.long ? this.productList.long : this.productList.from_long,
      ],
      [this.productList.to_lat, this.productList.to_long],
    ];
  },
  components: {
    MapBlock: () => ({
      component: import("../../components/map/map-order"),
    }),
  },
};
</script>

<style scoped>
.order-item__containers {
  border-radius: 0.5rem;
  overflow: hidden;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.order-item__container-list {
  min-height: 1rem;
  height: 5rem;
  max-height: 40rem;
  overflow-y: auto;
  resize: vertical;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-sizing: content-box;
}
.order-item__container-list::-webkit-scrollbar {
  visibility: hidden;
  width: 8px;
}
.order-item__container-list::-webkit-scrollbar-thumb {
  visibility: visible;
  width: 8px;
  background-color: #bbb7;
  border-radius: 999px;
}
.order-item__container-list::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.order-item__container-list::-webkit-scrollbar-thumb:active {
  background-color: #aaa;
}
.order-item__container-list::-webkit-scrollbar-track {
  background-color: #eee;
}
.order {
  margin-top: 35px;
  align-items: flex-start;
}
.order__block {
  width: 50%;
  padding: 0 10px;
}
.order__map {
  width: 48%;
  height: 600px;
}
.order__head {
  padding: 10px;
}
.order-items {
  margin-top: 10px;
}
.order-item {
  /* height: 30px; */
  padding: 10px;
  text-align: right;
}
.order__main-block {
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  /*height: auto;*/
}
.order-item__key {
  margin-right: 60px;
  text-align: left;
}
.titles {
  padding-left: 10px;
  font-family: Arial, sans-serif;
  font-weight: 900;
}
</style>
